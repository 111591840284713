import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";
import { Box, useTheme } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { useDispatch, useSelector } from "react-redux";
import { setImage } from "../../Redux/GlobalSlice";
import bg from "../../Assests/slider-bg.jpg";
import defaultImg from "../../Assests/slider-4.jpg";
import { imagesData } from "./constant";
import { ThemeContext } from "../../services/ThemeContext";
import { useOptimistic } from "react";

export function SliderV3() {
  const mainRef = useRef(null);
  const thumbnailRef = useRef(null);
  const dispatch = useDispatch();
  const selectedImage = useSelector((state) => state?.data?.data?.image);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const theme = useTheme();
  const { toggleColorMode } = useContext(ThemeContext);

  const mainOptions = useMemo(() => {
    const mainOptions = {
      /*height: 535,*/
      type: "fade",
      heightRatio: 0.3,
      pagination: false,
      arrows: false,
      rewind: true,
      autoplay: true,
      rewindSpeed: 2000,
      extensions: [AutoScroll],
      autoScroll: {
        /**
         * The scroll speed as pixel per frame.
         */
        speed: 10,
        /**
         * Determines whether to start scrolling the slider after initialization.
         */
        autoStart: true,
        /**
         * Determines whether to rewind the slider when it reaches the end.
         */
        rewind: true,
      },
      breakpoints: {
        1000: {
          perPage: 1,
        },
      },
      onMove: (splide, newIndex) => {
        setCurrentSlideIndex(newIndex);
        dispatch(setImage(imagesData[newIndex]?.image || defaultImg));
      },
    };
    return mainOptions;
  }, []);


  const thumbnailOptions = useMemo(() => {
    const thumbnailOptions = {
      fixedWidth: 226,
      fixedHeight: 120,
      start: 0,
      perPage: 5,
      gap: 30,
      isNavigation: true,
      autoplay: true,
      focus: "center",
      pagination: false,
      cover: true,
      rewind: true,
      borderRadius: 15,
    };
    return thumbnailOptions;
  }, [currentSlideIndex]);

  const handleThumbnailClick = (selectedImage, index, e) => {
    e.preventDefault();
    dispatch(setImage(selectedImage));
    if (mainRef.current) {
      mainRef.current.splide.go(index);
    }
  };

  useEffect(() => {
    if (imagesData[currentSlideIndex]) {
      dispatch(setImage(imagesData[currentSlideIndex].image));
    }
  }, [currentSlideIndex, dispatch]);

  useEffect(() => {
    if (selectedImage) {
      const index = imagesData.findIndex(
        (image) => image.image === selectedImage
      );
      if (index !== -1 && mainRef.current) {
        mainRef.current.splide.go(index);
      }
    }
  }, [selectedImage]);

  return (
    <Box className="App_">
      <Box
        style={{
          backgroundImage: `url(${theme.palette.mode === "dark" ? null : bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
          padding: "50px",
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <Splide options={mainOptions} ref={mainRef}>
          {imagesData.map((slide, index) => (
            <SplideSlide
              key={index}
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
                color: (theme) => theme.palette.text.primary,
              }}
            >
              <Box>
                <img
                  src={slide.image}
                  alt={`Image ${index}`}
                  style={{
                    borderRadius: 15,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              </Box>
            </SplideSlide>
          ))}
        </Splide>
        <Box
          className="slider-thumbnail"
          sx={{
            height: "100%",
            width: "100%",
            color: (theme) => theme.palette.text.primary,
          }}
        >
          <Splide options={thumbnailOptions} ref={thumbnailRef}>
            {imagesData.map((thumbnail, index) => (
              <SplideSlide
                key={index}
                onClick={(e) => handleThumbnailClick(thumbnail.image, index, e)}
                style={{ borderRadius: 15 }}
              >
                <img
                  src={thumbnail.image}
                  alt={`Thumbnail ${index + 1}`}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              </SplideSlide>
            ))}
          </Splide>
        </Box>
      </Box>
    </Box>
  );
}
