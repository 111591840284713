import  NewspaperIcon from '@mui/icons-material/Newspaper';
// Himalayan Startup Trek 2022
//   July 01, 2022

//  IIT Mandi
export const content=[
    {id:1, icon:<NewspaperIcon sx={{ color: 'orange' }} />, title:'Himalayan Startup Trek 2022'},
    {id:2, icon:<NewspaperIcon sx={{ color: 'orange' }} />, title:'Himalayan Startup Trek 2022'},
    {id:3, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:4, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:5, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:6, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:7, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:8, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:9, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:10, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:11, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:12, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:13, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:14, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:15, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:16, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:17, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:18, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
    {id:19, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022'},
]
export const content1=[
    {id:1, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022Workshop on Idea to Business Plan by Mr. Raj Bharat'},
    {id:2, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022Workshop on Idea to Business Plan by Mr. Raj Bharat'},
    {id:3, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022Workshop on Idea to Business Plan by Mr. Raj Bharat'},
    {id:4, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022Workshop on Idea to Business Plan by Mr. Raj Bharat'},
    {id:5, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022Workshop on Idea to Business Plan by Mr. Raj Bharat'},
    {id:6, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022Workshop on Idea to Business Plan by Mr. Raj Bharat'},
    {id:7, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022Workshop on Idea to Business Plan by Mr. Raj Bharat'},
    {id:8, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022Workshop on Idea to Business Plan by Mr. Raj Bharat'},
    {id:9, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022Workshop on Idea to Business Plan by Mr. Raj Bharat'},
    {id:10, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Himalayan Startup Trek 2022Workshop on Idea to Business Plan by Mr. Raj Bharat'},
]