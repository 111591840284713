import * as React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useCreateRoutesMutation,
  useUpdateRoutesMutation,
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setRoutesData } from "../../Redux/GlobalSlice";
import { ThemeContext } from "../../services/ThemeContext";

export default function UpsertRoutes({ state, toggleDrawer, showCase }) {
  const [createRoutes] = useCreateRoutesMutation();
  const [routesUpdate] = useUpdateRoutesMutation(); // Get Incubation list
  const routesData = useSelector((state) => {
    return state?.data?.data?.routesData;
  });
  const dispatch = useDispatch();
  const {fontSize } = React.useContext(ThemeContext);
  const schema = yup.object().shape({
    fullName: yup.string().required("Full Name is required"),
    path: yup.string().required("Path is required"),
    url: yup.string().required("Url is required"),
    icon: yup.string(),
    sequence:yup.number().required("Sequence is required"),
  });
  const formik = useFormik({
    initialValues: {
      fullName: "",
      path: "",
      url: "",
      icon: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const { data } = await createRoutes({
          name: values.fullName,
          path: values.path,
          frontend_url: values?.url,
          icon: values?.icon,
          sequence:values?.sequence
        });
        if (data?.success) {
          toast.success("Route is Created successfully", {
            position: "top-center",
            autoClose:  2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          formik.setFieldValue("fullName", "");
          formik.setFieldValue("path", "");
          formik.setFieldValue("url", "");
          formik.setFieldValue("icon", "");
          formik.setFieldValue("sequence", "");
          toggleDrawer("right", false)(values);
        }
      } catch (error) {
        console.log("error=>", error);
      }
    },
  });
  const handleUpsert = async (e) => {
    if (showCase === "edit") {
      if (formik.isValid) {
        const data = await routesUpdate({
          id: routesData?._id,
          payload: {
            name: formik?.values?.fullName,
            path: formik?.values?.path,
            frontend_url: formik?.values?.url,
            icon: formik?.values?.icon,
            sequence:formik?.values?.sequence
          },
        });
        if (data?.data?.success) {
          toast.success("Route is Updated successfully", {
            position: "top-center",
            autoClose:  2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          formik.setFieldValue("fullName", "");
          formik.setFieldValue("path", "");
          formik.setFieldValue("url", "");
          formik.setFieldValue("icon", "");
          formik.setFieldValue("sequence", "");
          toggleDrawer("right", false)(e);
        }
      }
    } else {
      formik.handleSubmit();
    }
  };

  React.useEffect(() => {
    if (showCase === "edit") {
      formik.setFieldValue("fullName", routesData?.name);
      formik.setFieldValue("path", routesData?.path);
      formik.setFieldValue("url", routesData?.frontend_url);
      formik.setFieldValue("icon", routesData?.icon);
      formik.setFieldValue("sequence", routesData?.sequence);
    }
  }, [routesData,state["right"]]);
  const handleClose=(event)=>{
    formik.setValues((prev)=>({
      ...prev,
      fullName:"",
      path:"",
      url:"",
      icon:"",
      sequence:"",
    }));
    dispatch(setRoutesData({}))
    toggleDrawer("right", false)(event);
  }
  return (
    <React.Fragment>
       <ToastContainer />
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={(event) => {
          toggleDrawer("right", false)(event);
          formik.resetForm();
        }}
      >
        {/* Content inside the Drawer goes here */}
        <Box role="presentation">
          <Box width={"95%"} marginLeft={"auto"} marginRight={"auto"} mb={2}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
            >
              {`${showCase === "edit" ? "Update" : "Add"} Routes`}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {/* <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Name:
                  </Typography> */}
                  <FormControl fullWidth>
                    <TextField
                      sx={{ width: "100%" }}
                      margin="normal"
                      required
                      fullWidth
                      id="full-name"
                      label="Full Name"
                      name="fullName"
                      autoComplete="off"
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.fullName &&
                        Boolean(formik.errors.fullName)
                      }
                      helperText={
                        formik.touched.fullName && formik.errors.fullName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {/* <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Sequence:
                  </Typography> */}
                  <FormControl fullWidth>
                    <TextField
                      sx={{ width: "100%" }}
                      type="number"
                      margin="normal"
                      required
                      fullWidth
                      id="sequence"
                      label="sequence"
                      name="sequence"
                      autoComplete="off"
                      value={formik.values.sequence}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.sequence &&
                        Boolean(formik.errors.sequence)
                      }
                      helperText={
                        formik.touched.sequence && formik.errors.sequence
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {/* <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Path:
                  </Typography> */}
                  <FormControl fullWidth>
                    <TextField
                      sx={{ width: "100%" }}
                      margin="normal"
                      required
                      fullWidth
                      id="full-name"
                      label="Path"
                      name="path"
                      autoComplete="off"
                      value={formik.values.path}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.path && Boolean(formik.errors.path)}
                      helperText={formik.touched.path && formik.errors.path}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {/* <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Icon:
                  </Typography> */}
                  <FormControl fullWidth>
                    <TextField
                      sx={{ width: "100%" }}
                      margin="normal"
                      fullWidth
                      id="icon" // <-- Change the id to "icon"
                      label="Icon"
                      name="icon"
                      autoComplete="off"
                      value={formik.values.icon}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.icon && Boolean(formik.errors.icon)}
                      helperText={formik.touched.icon && formik.errors.icon}      
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {/* <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Url:
                  </Typography> */}
                  <FormControl fullWidth>
                    <TextField
                      sx={{ width: "100%" }}
                      margin="normal"
                      fullWidth
                      id="url" // <-- Change the id to "url"
                      label="Url"
                      name="url" // <-- Change the name to "url"
                      autoComplete="url"
                      value={formik.values.url}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.url && Boolean(formik.errors.url)}
                      helperText={formik.touched.url && formik.errors.url}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", marginTop: "95%" }}>
                <Button
                  // type="submit"
                  onClick={(e) => {
                    handleUpsert(e);
                  }}
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 2,
                    borderRadius: 1,
                  }}
                >
                  {showCase !== "edit" ? "Save" : "Update"}
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: 1,
                  }}
                  onClick={(event) => {
                    toggleDrawer("right", false)(event);
                  }}
                >
                  Cancel
                </Button>
              </Grid>

              {/* Add your submit button here */}
            </Box>
            {/* </Paper> */}
          </Box>
        </Box>
        <IconButton
          onClick={(event) => {
            handleClose(event) 
          }}
          style={{ position: "absolute", top: "8px", right: "8px" }}
        >
          <CloseIcon />
        </IconButton>
      </Drawer>
    </React.Fragment>
  );
}
