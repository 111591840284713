import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { IconButton, InputAdornment, Paper, useTheme } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  api,
  useForgetPasswordMutation,
  useLoginUserMutation,
  useLoginUserOtpMutation,
  usePostOTPMutation,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {
  setForgetEmail,
  setLoginDetails,
  setSideBarContent,
} from "../../Redux/GlobalSlice";
import smallLogo from "../../Assests/hp_icon.png";
import black_bg from "../../Assests/Event-bg.png";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { CheckTokenValidity } from "../../Helpers/CheckTokenValidity";
import { useTranslation } from "react-i18next";
import PublishIcon from "@mui/icons-material/Publish";
import LoadingButton from "@mui/lab/LoadingButton";

const validateSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),

  //   password: yup
  //     .string("Please enter password")
  //     .required("Password is required"),
});

const ForgotPassword = () => {
  const [value, setValue] = useState("1"); // State to manage active tab
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPField, setShowOTPField] = useState(false);
  // const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginUser] = useLoginUserMutation();
  const [forgetPass] = useForgetPasswordMutation();
  const [loginUserOtp] = useLoginUserOtpMutation();
  const [postMob] = usePostOTPMutation();
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const {data:routeList}= useMenuDetailsQuery();
  const [routeList] = api.endpoints.menuDetails.useLazyQuery();

  const [captcha, setCaptcha] = useState("");
  // const [captcha1, setCaptcha1] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      //   password: "",
      // mobile: "",
      // otp: "", // Added OTP field
    },
    validationSchema: validateSchema,

    onSubmit: async (values) => {
      console.log("🚀 ~ onSubmit: ~ values:", values);
      try {
        setLoading(true);
        const res = await forgetPass({ email: values.email });
        dispatch(setForgetEmail(values.email));
        console.log("🚀 ~ onSubmit: ~ res:", res);
        if (res?.data?.success) {
          toast.success("Email sent to change the password.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/change-password");
          formik.resetForm();
        } else {
          // setLoading(false);
          toast.error(res?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        // setLoading(false);
        console.log("🚀 ~ onSubmit: ~ error:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  //   useEffect(() => {
  //     // setTimeout(()=>{
  //     loadCaptchaEnginge(6, "grey");
  //     // },100)
  //   }, [value]);

  const handleLoginWithOTP = () => {
    navigate("/sign-in-otp");
    // formik.setFieldValue("login_with_otp", true);
  };
  return (
    <Box
      component="main"
      maxWidth="xxl"
      sx={{
        display: "flex",
        flexDirection: "row",
        background: `url(${
          theme.palette.mode === "dark" ? null : black_bg
        }) center center no-repeat`, // Center the background image
        backgroundSize: "cover",
        width: "100%",
        height: "80%",
        justifyContent: "space-around",
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
      }}
    >
      {/* <Box> */}
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          width: { xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" }, // Adjust width for different screens
        }}
      >
        <CssBaseline />
        <ToastContainer />

        <Paper
          sx={{
            padding: "2em 2em",
            borderRadius: "3em",
            marginBottom: "4em",
            marginTop: "3em",
            //   maxWidth:'100%'
          }}
          elevation={5}
        >
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar> */}
            <img src={smallLogo} alt="" />
            <br />
            <Typography variant="h5" align="center" fontWeight="600">
              {t("Forget Password")}
            </Typography>
            <br />
            {/* <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                  > */}
            <TextField
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              autoFocus
            />

            {/* PASSWORD */}
            {/* <TextField
                      margin="dense"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    /> */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box className="captcha-continer">
                {/* <LoadCanvasTemplate
                          reloadText="Get a new code"
                          reloadColor="white"
                        /> */}
                {/* <Box className="input-block">
                          <TextField
                            margin="dense"
                            required
                            fullWidth
                            name="captcha"
                            type="text"
                            id="captcha"
                            placeholder="Enter Verification code"
                            value={captcha}
                            onChange={(e) => setCaptcha(e.target.value)}
                          />
                        </Box> */}
              </Box>
            </Grid>
            {/* <Button
              
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
              Submit
            </Button> */}

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              disabled={loading}
              endIcon={<PublishIcon />}
              loading={loading}
              loadingPosition="end"
            >
              Submit
            </LoadingButton>
            {/* <Grid className="or-text">
                      OR
                    </Grid>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 1, mb: 1 }}
                      onClick={handleLoginWithOTP}
                    >
                      Login with OTP
                    </Button> */}
            {/* <Grid container textAlign={"center"}>
                      <Grid item xs>
                        <Link
                          to="/"
                          variant="body2"
                          style={{ textDecoration: "none",color: theme.palette.mode==="dark"?"#fff":"", }}
                        >
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item textAlign={"center"} width={"100%"}>
                        <Link
                          to="/sign-up"
                          variant="body2"
                          style={{
                            textDecoration: "none",
                            textAlign: "center",
                            color: theme.palette.mode==="dark"?"#fff":""
                          }}
                        >
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid> */}
            {/* </Box> */}
          </Box>
        </Paper>
      </Container>
      {/* </Box> */}
    </Box>
  );
};

export default ForgotPassword;
