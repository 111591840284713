import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Grid,
  Divider,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
// import {
//   api,
//   useAllApplicantQuery,
//   useSingleApplicantQuery,
//   useUpdateApplicantStatusMutation,
// } from "../../services/api";
import { toast, ToastContainer } from "react-toastify";
// import { useChangeStatusByTechnicalMutation } from "../../services/api";

// const validationSchema = Yup.object().shape({
//   status: Yup.string().required("New Status is required"),
//   remarks: Yup.string().required("Remarks is required"),
// });

const PreviewOldApplication = ({ showOldApplication, setShowOldApplication,oldapplicationData, setOldApplicationData}) => {
  const {
    referenceId,
    startup_name,
    business_address,
    business_entity_type,
    commencementDate,
    funding_details,
    idea,
    incubation_centre,
    initial_investment,
    sector,
    social_public,
    udyam_no,
    uniqueness,
    user_id,
  } = oldapplicationData || {}; // Avoids errors if data is missing
  // const [sendToTechnical] = useChangeStatusByTechnicalMutation();
  const formik = useFormik({
    initialValues: {
      terms: false,
    },
    validationSchema: null,
    onSubmit: (values) => {
      // Handle form submission
    },
  });
  //   React.useEffect(() => {
  //     async function apiData() {
  //       if (selectedRow) {
  //         await singleApplicant(selectedRow?._id);
  //         formik.setValues((prev)=>({
  //           ...prev,
  //           status: allApplicant?.application?.status ,
  //           remarks: allApplicant?.application?.remarks ,
  //         }));
  //       }
  //     }
  //     apiData()
  //   }, [selectedRow?._id, allApplicant?.application, open]);

  //   const handleStatusChange = async () => {
  //         try {
  //           let data = {
  //             id:selectedId,
  //             payload: {
  //               status: formik.values.status,
  //               remarks: formik.values.remarks,
  //             },
  //           };
  //           const response = await sendToTechnical(data);
  //           if (response?.data?.success) {
  //             toast.success(response.data.message, {
  //               position: "top-center",
  //               autoClose: 2000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //               theme: "light",
  //             });
  //             handleClose()
  //             setOpen(false)
  //             // setTimeout(() => {
  //             //   navigate("/application-status");
  //             // }, 1000);
  //           } else if (response?.error) {
  //             toast.error(response?.error?.data?.message, {
  //               position: "top-center",
  //               autoClose: 3000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //               theme: "light",
  //             });
  //             handleClose()
  //             setOpen(false)
  //           }
  //         } catch (error) {
  //           console.error("Error submitting data:", error);
  //         } finally {
  //         }
  //   };
  const handleOk=()=>{
  setShowOldApplication(false)
  }

  return (
    <>
      <ToastContainer />
      <Dialog
        open={showOldApplication}
        onClose={() => setShowOldApplication(false)}
        maxWidth={'md'}
        // sx={{ zIndex: 10 }}
      >
        {/* <DialogTitle>Applicant's Rights and Disclaimers</DialogTitle> */}
        <DialogContent>
          <Typography
            variant="h7"
            sx={{ fontWeight: 700, fontSize: 30 ,  justifyContent:'center', display:'flex' }}
          >
            Preview Application
          </Typography>
          {/* <DialogContentText></DialogContentText> */}
          <Divider sx={{ width: "100%", my: 1 }} />{/* Adds divider every two items */}
          <Grid container spacing={2}>
      {/* Utility function for rendering fields */}
      {[
        { label: "Reference ID", value: referenceId },
        { label: "User Name", value: user_id?.name },
        { label: "Startup Name", value: startup_name },
        { label: "Business Address", value: business_address },
        { label: "Business Entity Type", value: business_entity_type?.name },
        { label: "Commencement Date", value: commencementDate ? new Date(commencementDate).toLocaleDateString() : "N/A" },
        { label: "Funding Details", value: funding_details },
        { label: "Startup Idea", value: idea },
        { label: "Incubation Centre", value: incubation_centre?.name },
        { label: "Initial Investment", value: `Rs. ${initial_investment}` },
        { label: "Sector", value: sector?.name },
        { label: "Social Impact", value: social_public },
        { label: "Udyam No.", value: udyam_no || "N/A" },
        { label: "Uniqueness", value: uniqueness },
        { label: "User Email", value: user_id?.email },
      ].map((field, index) => (
        <React.Fragment key={index}>
          <Grid container flexDirection="column" display="flex" justifyContent="end" item xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">{field.label}</Typography>
            <Typography variant="subtitle2">{field.value || "N/A"}</Typography>
          </Grid>
          {index % 2 !== 0 && <Divider sx={{ width: "100%", my: 1 }} />} {/* Adds divider every two items */}
        </React.Fragment>
      ))}
    </Grid>
         
          <Divider/>
        
         
        </DialogContent>
        <DialogActions>
          {/* <Button
            sx={{
              borderRadius: "5px",
              backgroundColor: "#CCCCCC",
              marginRight: "8px",
              color: "#000",
            }}
          >
            Cancel
          </Button> */}
          <Button
            onClick={() => setShowOldApplication(false)}
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleOk}
            sx={{
              borderRadius: "5px",
              backgroundColor: "#3498db",
              color: "#000",
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PreviewOldApplication;
