import React, { useState } from "react";
import {
  Grid,
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { useGetOldApplicationQuery, useUpdateBankDetailsMutation } from "../../services/api";
import { useNavigate } from "react-router-dom";

// Validation Schema with Yup
const validationSchema = Yup.object({
  bank_name: Yup.string().required("Bank Name is required"),
  account_number: Yup.string()
    .matches(/^\d{9,18}$/, "Account Number must be between 9 and 18 digits")
    .required("Account Number is required"),
  ifsc_code: Yup.string()
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code format")
    .required("IFSC Code is required"),
});

export default function BankDetailsPopup({
  show,
  setShow,
  handleClose,
  appId,
}) {
  const [updatedBnk] = useUpdateBankDetailsMutation();
const navigate=useNavigate()
  const formik = useFormik({
    initialValues: {
      bank_name: "",
      account_number: "",
      ifsc_code: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("Form Submitted with values:", values);

      try {
        let bank_detail = [];
        const obj = {
          bank_name: values?.bank_name,
          account_number: values?.account_number,
          ifsc_code: values?.ifsc_code,
        };
        bank_detail.push(obj);
        const { data } = await updatedBnk({
          bank_detail: bank_detail,
          id: appId,
        });
        if (data?.success) {
          toast.success(data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
          handleClose();
          navigate(`/progress-report/${appId}`);
        }
        console.log("🚀 ~ onSubmit:async ~ data:", data);
      } catch (error) {}
    },
  });

  // Handle closing the dialog
  const handleCloseDialog = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Dialog open={show} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Enter Bank Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Bank Name"
              name="bank_name"
              value={formik.values.bank_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.bank_name && Boolean(formik.errors.bank_name)
              }
              helperText={formik.touched.bank_name && formik.errors.bank_name}
              variant="outlined"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Account Number"
              name="account_number"
              value={formik.values.account_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.account_number &&
                Boolean(formik.errors.account_number)
              }
              helperText={
                formik.touched.account_number && formik.errors.account_number
              }
              variant="outlined"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="IFSC Code"
              name="ifsc_code"
              value={formik.values.ifsc_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.ifsc_code && Boolean(formik.errors.ifsc_code)
              }
              helperText={formik.touched.ifsc_code && formik.errors.ifsc_code}
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          sx={{ backgroundColor: "#f0f0f0", color: "#000" }}
        >
          Cancel
        </Button>
        <Button
          onClick={formik.handleSubmit}
          sx={{ backgroundColor: "#3498db", color: "#fff" }}
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        >
          {formik.isSubmitting ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Save & Generate PR"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
