import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { IconButton, InputAdornment, Paper, useTheme } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  api,
  useChangePasswordMutation,
  useLoginUserMutation,
  useLoginUserOtpMutation,
  usePostOTPMutation,
  useVerifyOtpMutation,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setLoginDetails, setSideBarContent } from "../../Redux/GlobalSlice";
import smallLogo from "../../Assests/hp_icon.png";
import black_bg from "../../Assests/Event-bg.png";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { CheckTokenValidity } from "../../Helpers/CheckTokenValidity";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const [verify, setVerify] = useState(false);
  const validateSchema = yup.object({
    //   email: yup
    //     .string("Enter your email")
    //     .email("Enter a valid email")
    //     .required("Email is required"),

    // password: yup.string().required("Password is required"),
    Cpassword: !verify
      ? yup.string()
      : yup
          .string("Enter your password")
          .required("Confirm Password Required")
          .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
    newPassword: !verify
      ? yup.string()
      : yup
          .string("Enter your New password")
          .min(8, "New Password should be of minimum 8 characters length")
          .required("New Password is required")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
          ),
    otp: verify ? yup.string() : yup.string().required("OTP is required"),
  });
  const [value, setValue] = useState("1"); // State to manage active tab
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPField, setShowOTPField] = useState(false);
  // const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginUser] = useLoginUserMutation();
  const [userData] = api.endpoints.applicationDetail.useLazyQuery();
  const [loginUserOtp] = useLoginUserOtpMutation();
  const [changePass] = useChangePasswordMutation();
  const [verifyOTP] = useVerifyOtpMutation();
  const theme = useTheme();
  const { t } = useTranslation();
  const { forgetEmail = "" } = useSelector((state) => {
    return state?.data?.data;
  });
  console.log(
    "🚀 ~ const{forgetEmail}=useSelector ~ forgetEmail:",
    forgetEmail
  );

  // const {data:routeList}= useMenuDetailsQuery();
  const [routeList] = api.endpoints.menuDetails.useLazyQuery();

  const [captcha, setCaptcha] = useState("");
  // const [captcha1, setCaptcha1] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      Cpassword: "",
      newPassword: "",
      opt: "",
      // mobile: "",
      // otp: "", // Added OTP field
    },
    validationSchema: validateSchema,

    onSubmit: async (values) => {
      try {
        if (!verify) {
          const res = await verifyOTP({
            email: values?.email,
            otp: values?.otp,
          });
          console.log("🚀 ~ onSubmit: ~ data:", res);
          if (res?.data?.success) {
            setVerify(true);
          } else {
            toast.error(res?.error?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else {
          const response = await changePass({
            email: values?.email,
            otp: values?.otp,
            password: values?.newPassword,
            confirmpassword: values?.Cpassword,
          });
          console.log("🚀 ~ onSubmit: ~ response:", response);

          if (response?.data?.success) {
            toast.success(response?.data?.data, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/sign-in");
            }, 1000);
          } else {
            toast.error(response?.error?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      } catch (error) {
        console.log("🚀 ~ onSubmit: ~ error:", error);
      }
    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  useEffect(() => {
    formik.setFieldValue("email", forgetEmail);
  }, [forgetEmail]);
  // const handleOTPVerification=()=>{

  // }

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  //   useEffect(() => {
  //     // setTimeout(()=>{
  //     loadCaptchaEnginge(6, "grey");
  //     // },100)
  //   }, [value]);

  const handleLoginWithOTP = () => {
    navigate("/sign-in-otp");
    // formik.setFieldValue("login_with_otp", true);
  };
  return (
    <Box
      component="main"
      maxWidth="xxl"
      sx={{
        display: "flex",
        flexDirection: "row",
        background: `url(${
          theme.palette.mode === "dark" ? null : black_bg
        }) center center no-repeat`, // Center the background image
        backgroundSize: "cover",
        width: "100%",
        height: "80%",
        justifyContent: "space-around",
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
      }}
    >
      {/* <Box> */}
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          width: { xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" }, // Adjust width for different screens
        }}
      >
        <CssBaseline />
        <ToastContainer />

        <Paper
          sx={{
            padding: "2em 2em",
            borderRadius: "3em",
            marginBottom: "4em",
            marginTop: "3em",
            //   maxWidth:'100%'
          }}
          elevation={5}
        >
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar> */}
            <img src={smallLogo} alt="" />
            <br />
            <Typography variant="h5" align="center" fontWeight="600">
              {t("Change Password")}
            </Typography>
            <br />
            {/* <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                  > */}
            {/* <TextField
                      margin="dense"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="off                     value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      autoFocus
                    /> */}

            {/* PASSWORD */}
            <TextField
              margin="dense"
              inputProps={{ readOnly: true }}
              // required
              fullWidth
              name="email"
              label="Email"
              type={"email"}
              id="email"
              autoComplete="off"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <br />
            <TextField
              inputProps={{
                readOnly: verify ? true : false,
              }}
              required
              fullWidth
              name="otp"
              label="OTP"
              type="text"
              id="otp"
              autoComplete="off"
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={formik.touched.otp && formik.errors.otp}
              // onChange={(e) => handleChange(e)}
            />

            <br />
            {verify ? (
              <>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  name="newPassword"
                  label="New password"
                  type={showPassword ? "text" : "password"}
                  id="New-Password"
                  autoComplete="off"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword)
                  }
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <br />
                <TextField
                  required
                  fullWidth
                  name="Cpassword"
                  label="Confirm Password"
                  type="password"
                  id="confirm-password"
                  autoComplete="off"
                  value={formik.values.Cpassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.Cpassword && Boolean(formik.errors.Cpassword)
                  }
                  helperText={
                    formik.touched.Cpassword && formik.errors.Cpassword
                  }
                  // onChange={(e) => handleChange(e)}
                />
              </>
            ) : (
              <></>
            )}

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box className="captcha-continer">
                {/* <LoadCanvasTemplate
                          reloadText="Get a new code"
                          reloadColor="white"
                        /> */}
                {/* <Box className="input-block">
                          <TextField
                            margin="dense"
                            required
                            fullWidth
                            name="captcha"
                            type="text"
                            id="captcha"
                            placeholder="Enter Verification code"
                            value={captcha}
                            onChange={(e) => setCaptcha(e.target.value)}
                          />
                        </Box> */}
              </Box>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
              {!verify ? "Verify OTP" : "Submit"}
            </Button>
            {/* <Grid className="or-text">
                      OR
                    </Grid>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 1, mb: 1 }}
                      onClick={handleLoginWithOTP}
                    >
                      Login with OTP
                    </Button> */}
            {/* <Grid container textAlign={"center"}>
                      <Grid item xs>
                        <Link
                          to="/"
                          variant="body2"
                          style={{ textDecoration: "none",color: theme.palette.mode==="dark"?"#fff":"", }}
                        >
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item textAlign={"center"} width={"100%"}>
                        <Link
                          to="/sign-up"
                          variant="body2"
                          style={{
                            textDecoration: "none",
                            textAlign: "center",
                            color: theme.palette.mode==="dark"?"#fff":""
                          }}
                        >
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid> */}
            {/* </Box> */}
          </Box>
        </Paper>
      </Container>
      {/* </Box> */}
    </Box>
  );
};

export default ChangePassword;
