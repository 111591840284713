import * as React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
    useCreateRolesMutation, useUpdateRolesMutation,
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setRolesData } from "../../Redux/GlobalSlice";

export default function UpsertRoles({ state, toggleDrawer, showCase }) {
  const [createRoles] = useCreateRolesMutation();
  const [rolesUpdate] = useUpdateRolesMutation(); // Get Incubation list
  const rolesData = useSelector((state) => {
    return state?.data?.data?.rolesData;
  });
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    fullName: yup.string().required("Full Name is required"),
  });
  const formik = useFormik({
    initialValues: {
      fullName: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const { data } = await createRoles({
          name: values.fullName,
        });
        if (data?.success) {
          toast.success("Role is Created successfully", {
            position: "top-center",
            autoClose:  2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          formik.setFieldValue("fullName", "");
          toggleDrawer("right", false)(values);
        }
      } catch (error) {
        console.log("error=>", error);
      }
    },
  });
  const handleUpsert = async (e) => {
    if (showCase === "edit") {
      if (formik.isValid) {
        const data = await rolesUpdate({
          id: rolesData?._id,
          payload: {name:formik?.values?.fullName},
        });
        if (data?.data?.success) {
          toast.success("Role is Updated successfully", {
            position: "top-center",
            autoClose:  2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
            formik.setFieldValue("fullName", "");
          toggleDrawer("right", false)(e);
        }
      }
    } else {
      formik.handleSubmit();
    }
  };

  React.useEffect(() => {
    if (showCase === "edit") {
      formik.setFieldValue("fullName", rolesData?.name);
    } 
  }, [rolesData, state["right"]]);
  const handleClose=(event)=>{
    formik.setValues((prev)=>({
      ...prev,
      fullName:"",
    }));
    dispatch(setRolesData({}))
    toggleDrawer("right", false)(event);
  }
  return (
    <React.Fragment>
       <ToastContainer />
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={(event) => {
          toggleDrawer("right", false)(event);
          formik.resetForm();
        }}
      >
        {/* Content inside the Drawer goes here */}
        <Box role="presentation">
          <Box width={"95%"} marginLeft={"auto"} marginRight={"auto"} mb={2}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
            >
              {`${showCase === "edit" ? "Update" : "Add"} Roles`}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {/* <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontWeight: 400 }}
                  >
                    Name:
                  </Typography> */}
                  <FormControl fullWidth>
                    <TextField
                      sx={{ width: "100%" }}
                      margin="normal"
                      required
                      fullWidth
                      id="full-name"
                      label="Full Name"
                      name="fullName"
                      autoComplete="full-name"
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.fullName &&
                        Boolean(formik.errors.fullName)
                      }
                      helperText={
                        formik.touched.fullName && formik.errors.fullName
                      }
                    //   autoFocus={false}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", marginTop: "95%" }}>
                <Button
                  // type="submit"
                  onClick={(e) => {
                    handleUpsert(e);
                  }}
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 2,
                    borderRadius: 1,
                  }}
                >
                  {showCase !== "edit" ? "Save" : "Update"}
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: 1,
                  }}
                  onClick={(event) => {
                    toggleDrawer("right", false)(event);
                    formik.setFieldValue("fullName", "");
                  }}
                >
                  Cancel
                </Button>
              </Grid>

              {/* Add your submit button here */}
            </Box>
            {/* </Paper> */}
          </Box>
        </Box>
        <IconButton
          onClick={(event) => {
            handleClose(event)
          }}
          style={{ position: "absolute", top: "8px", right: "8px" }}
        >
          <CloseIcon />
        </IconButton>
      </Drawer>
    </React.Fragment>
  );
}
