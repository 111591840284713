import "./style.scss";
import {
  Grid,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Typography,
  useTheme,
  Card,
  CardContent,
  Tab,
  Tabs,
  List,
  ListItem,
  Box,
  Paper,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Container,
} from "@mui/material";
import * as React from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import Tabs from '@mui/material/Tabs';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import black_bg from "../../Assests/auth-bg.jpg";
export const ScreenReader = () => {
  const [tabValue, setTabValue] = React.useState(false);
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const data = [
    {
      sr_no: 1,
      software_name: "Non Visual Desktop Access (NVDA)",
      website: "http://www.nvda-project.org/",
      type: "Free",
    },
    {
      sr_no: 2,
      software_name: "System Access To Go",
      website: "	http://www.satogo.com/",
      type: "Free",
    },
    {
      sr_no: 3,
      software_name: "Hal",
      website: "http://www.yourdolphin.co.uk/productdetail.asp?id=5",
      type: "Commercial",
    },
    {
      sr_no: 4,
      software_name: "JAWS",
      website: "http://www.freedomscientific.com/products/software/jaws/",
      type: "Commercial",
    },
    {
      sr_no: 5,
      software_name: "Supernova",
      website: "http://www.yourdolphin.co.uk/productdetail.asp?id=1",
      type: "Commercial",
    },
    {
      sr_no: 6,
      software_name: "Window-Eyes",
      website: "http://www.gwmicro.com/Window-Eyes/",
      type: "Commercial",
    },
  ];

  const data1 = [
    {
      sr_no: 1,
      software_name: "Dragon Naturally Speaking",
      website: "http://www.nuance.com/",
      type: "Commercial",
    },
    {
      sr_no: 2,
      software_name: "Windows Speech Recognition in Windows Vista",
      website:
        "http://www.microsoft.com/enable/products/windowsvista/speech.aspx",
      type: "Commercial",
    },
    {
      sr_no: 3,
      software_name: "Speech Recognition in Windows 7",
      website: "http://www.microsoft.com/enable/products/windows7/",
      type: "Commercial",
    },
  ];

  const data2 = [
    {
      sr_no: 1,
      software_name: "PDF content",
      download: "Adobe Acrobat Reader",
      link: "https://get.adobe.com/uk/reader/",
    },
    {
      sr_no: 2,
      software_name: "Word files",
      download: `Word Viewer (in any version till 2003), \n Microsoft Office Compatibility Pack for Word (for 2007 version)`,
      link: "https://www.microsoft.com/en-in/",
    },
    {
      sr_no: 3,
      software_name: "Excel files",
      download:
        "Excel Viewer 2003 (in any version till 2003), \n Microsoft Office Compatibility Pack for Excel (for 2007 version)",
      link: "https://www.microsoft.com/en-in/",
    },
    {
      sr_no: 4,
      software_name: "PowerPoint presentations",
      download:
        "	PowerPoint Viewer 2003 (in any version till 2003), \n  Microsoft Office Compatibility Pack for PowerPoint (for 2007 version)",
      link: "https://www.microsoft.com/en-in/",
    },
    {
      sr_no: 5,
      software_name: "Flash content",
      download: "Adobe Flash Player",
      link: "https://www.adobe.com/products/flashplayer/end-of-life-alternative.html",
    },
    {
      sr_no: 6,
      software_name: "Audio Files",
      download: "	Windows Media Player",
      link: "https://www.microsoft.com/en-in/windows/?r=1",
    },
  ];

  const commonStyle = {
    heading: { fontWeight: "bold", fontSize: "15px" },
  };
  React.useEffect(() => {
    setValue("1");
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();



  return (
    <Box
      className="screen-app-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${
          theme.palette.mode !== "light" ? null : black_bg
        }) `, // Center the background image
        backgroundSize: "cover",
        width: "100%",
        padding: "2rem",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <ToastContainer />
      {/* Inner container */}
      <Container>
        <Paper className="paper-card">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Screen Reader Access"
                    sx={{
                      fontSize: value === "1" ? 15 : 14,
                      fontWeight: value === "1" ? "bold" : "none",
                    }}
                    value="1"
                  />
                  <Tab
                    label="Speech Recognition Support"
                    sx={{
                      fontSize: value === "2" ? 15 : 14,
                      fontWeight: value === "2" ? "bold" : "none",
                    }}
                    value="2"
                  />
                  <Tab
                    label="Viewing in Formation in Various File Formats"
                    sx={{
                      fontSize: value === "3" ? 15 : 14,
                      fontWeight: value === "3" ? "bold" : "none",
                    }}
                    value="3"
                  />
                  <Tab
                    label="Accessiblity Statement"
                    sx={{
                      fontSize: value === "4" ? 15 : 14,
                      fontWeight: value === "4" ? "bold" : "none",
                    }}
                    value="4"
                  />
                </Tabs>
              </Box>
              <TabPanel value="1">
                <Typography variant="h7">
                  Startup Himachal Pardesh, Government Of India website complies
                  with World Wide Web Consortium (W3C) Web Content Accessibility
                  Guidelines (WCAG) 2.0 level AA. This will enable people with
                  visual impairments access the website using assistive
                  technologies, such as screen readers. The information of the
                  website is accessible with different screen readers, such as
                  JAWS. Following table lists the information about different
                  screen readers:
                </Typography>
                <br />
                <br />
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={commonStyle.heading}>Sr.No</TableCell>
                        <TableCell sx={commonStyle.heading}>
                          Screen Reader
                        </TableCell>
                        <TableCell sx={commonStyle.heading}>Website</TableCell>
                        <TableCell sx={commonStyle.heading}>
                          Free / Commercial
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.sr_no}</TableCell>
                          <TableCell>{row.software_name}</TableCell>
                          <TableCell   >
                          <Link
                              style={{
                                color: 
                                  theme.palette.mode === "dark"
                                    ? "#f0f0f0" // Light color for dark mode
                                    : theme.palette.text.primary, // Default color for light mode
                              }}
                              to={row.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {row.website}
                            </Link>

                          </TableCell>
                          <TableCell>{row.type}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value="2">
                <Typography variant="h7">
                  The Startup Himachal Pardesh portal complies with World Wide
                  Web Consortium (W3C) Web Content Accessibility Guidelines
                  (WCAG) 2.0 Level AA. This will enable people with mobility
                  impairments, people with visual impairments and senior
                  citizens to access the website using assistive technologies,
                  such as speech recognition software. The following table lists
                  the information about different speech recognition software:
                </Typography>
                <br />
                <br />
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={commonStyle.heading}>Sr.No</TableCell>
                        <TableCell sx={commonStyle.heading}>
                          Speech Recognition Software
                        </TableCell>
                        <TableCell sx={commonStyle.heading}>Website</TableCell>
                        <TableCell sx={commonStyle.heading}>
                          Free / Commercial
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data1.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.sr_no}</TableCell>
                          <TableCell>{row.software_name}</TableCell>
                          <TableCell>
                            <Link
                             style={{
                              color: 
                                theme.palette.mode === "dark"
                                  ? "#f0f0f0" // Light color for dark mode
                                  : theme.palette.text.primary, // Default color for light mode
                            }}
                              to={row.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {row.website}
                            </Link>
                          </TableCell>
                          <TableCell>{row.type}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value="3">
                {" "}
                <Typography variant="h7">
                  The information provided by this portal is available in
                  various file formats, such as Portable Document Format (PDF),
                  Word, Excel and PowerPoint. To view the information properly,
                  your browser need to have the required plug-ins or software.
                  For example, the Adobe Flash software is required to view the
                  Flash files. In case your system does not have this software,
                  you can download it from the Internet for free. The table
                  lists the required plug-ins needed to view the information in
                  various file formats.
                  <br />
                  Note: To view Microsoft Office 2007 files, install the
                  Microsoft Office Compatibility Pack along with the respective
                  viewer.
                </Typography>
                <Typography variant="h7"></Typography>
                <br />
                <br />
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={commonStyle.heading}>Sr.No</TableCell>
                        <TableCell sx={commonStyle.heading}>
                          Document Type
                        </TableCell>
                        <TableCell sx={commonStyle.heading}>Download</TableCell>
                        {/* <TableCell>Free / Commercial</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data2.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.sr_no}</TableCell>
                          <TableCell>{row.software_name}</TableCell>
                          <TableCell>
                            <Link
                             style={{
                              color: 
                                theme.palette.mode === "dark"
                                  ? "#f0f0f0" // Light color for dark mode
                                  : theme.palette.text.primary, // Default color for light mode
                            }}
                              to={row.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {row.download}
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value="4">
                <Typography variant="h7">
                  We are committed to ensure that the Startup Cell Himachal Pardesh
                  portal is accessible to all users irrespective of device in
                  use, technology or ability. It has been built, with an aim, to
                  provide maximum accessibility and usability to its visitors.
                  We have put in our best efforts to ensure that all information
                  on this portal is accessible to people with disabilities. For
                  example, a user with visual disability can access this portal
                  using assistive technologies. Contributing to the Accessible
                  India Campaign, Start-up Portal provides easy access for
                  persons with disabilities (PWD) with a range of features with
                  level A and AA Succses criteria by Adoption of WCAG Standards,
                  non text content in the form of underline links & image
                  titles, meaningful sequence to be able to to use tab along
                  with keyboard, feature to adjust brightness and contrast as
                  per requirement ability to resize text and more. The screen
                  reader access and speech recognition support related
                  information along with various viewing in formation in various
                  file formats are also provided on the portal for better
                  accessibility. If you have any problem or suggestion regarding
                  the accessibility of this portal, please give us feedback to
                  enable us to respond in a helpful manner and for necessary
                  improvements to be made available on the portal.
                </Typography>
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};
