import * as React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
    useCreateDistrictMutation,
  useGetStateQuery,
  useUpdateDistrictMutation,
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setDistrictData } from "../../Redux/GlobalSlice";

export default function UpsertDistrict({ state, toggleDrawer, showCase }) {
  const [createDistrict] = useCreateDistrictMutation();
  const [updateDistrict] = useUpdateDistrictMutation(); // Get Incubation list
  const districtData = useSelector((state) => {
    return state?.data?.data?.districtData;
  });
  const { data: stateList, isLoading, isError } = useGetStateQuery(
    //   {
    //   page: paginationModel.page + 1,
    //   limit: paginationModel.pageSize
    // }
  );
  const dispatch = useDispatch();  
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    state: yup.string().required("State is required"),
  });
    // {    
  //   "name" : "kullu",
  //       "state_id": "663dc0b2f86802e4b9f4c3b4"
  //   }
  const formik = useFormik({
    initialValues: {
      name: "",
      state:""
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const { data } = await createDistrict({
            name: values.name,
            state_id: values.state,
        });
        if (data?.success) {
          toast.success("State list is Created successfully", {
            position: "top-center",
            autoClose:  2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          formik.setFieldValue("name", "");
          toggleDrawer("right", false)(values);
        }
      } catch (error) {
        console.log("error=>", error);
      }
    },
  });


  const handleUpsert = async (e) => {
    if (showCase === "edit") {
      if (formik.isValid) {
        const data = await updateDistrict({
          id: districtData?._id,
          payload: {name:formik?.values?.name, state_id:formik?.values.state},
        });
        if (data?.data?.success) {
          toast.success("State list is Updated successfully", {
            position: "top-center",
            autoClose:  2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
            formik.setFieldValue("name", "");
            formik.setFieldValue("state", "");
            toggleDrawer("right", false)(e);
        }
      }
    } else {
      formik.handleSubmit();
    }
  };

  React.useEffect(() => {
    if (showCase === "edit") {
      formik.setFieldValue("name", districtData?.name);
      formik.setFieldValue("state", districtData?.state_id?._id);
    } 
  }, [districtData, state["right"]]);
  const handleClose=(event)=>{
    formik.setValues((prev)=>({
      ...prev,
      name:"",
      state:""
    }));
    // dispatch(setDistrictData({}))
    toggleDrawer("right", false)(event);
  }
  return (
    <React.Fragment>
          <ToastContainer />
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={(event) => {
          toggleDrawer("right", false)(event);
          formik.resetForm();
        }}
      >
        {/* Content inside the Drawer goes here */}
        <Box role="presentation">
          <Box width={"95%"} marginLeft={"auto"} marginRight={"auto"} mb={2}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
            >
              {`${showCase === "edit" ? "Update" : "Add"} District`}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {/* <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontWeight: 400 }}
                  >
                    Name:
                  </Typography> */}
                  <FormControl fullWidth>
                    <TextField
                      sx={{ width: "100%" }}
                      margin="normal"
                      required
                      fullWidth
                      id="Legislative-name"
                      label="Name"
                      name="name"
                      autoComplete="off"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.name &&
                        Boolean(formik.errors.name)
                      }
                      helperText={
                        formik.touched.name && formik.errors.name
                      }
                    //   autoFocus={false}
                    />
                  </FormControl>
                </Grid>
              <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="state">
                Select State
              </InputLabel>
              <Select
                margin="normal"
                required
                fullWidth
                id="state"
                label="Select State"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.state &&
                  Boolean(formik.errors.state)
                }
                autoComplete="off"
              >
                {stateList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", marginTop: "95%" }}>
                <Button
                  // type="submit"
                  onClick={(e) => {
                    handleUpsert(e);
                  }}
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 2,
                    borderRadius: 1,
                  }}
                >
                  {showCase !== "edit" ? "Save" : "Update"}
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: 1,
                  }}
                  onClick={(event) => {
                    toggleDrawer("right", false)(event);
                    formik.setFieldValue("name", "");
                  }}
                >
                  Cancel
                </Button>
              </Grid>

              {/* Add your submit button here */}
            </Box>
            {/* </Paper> */}
          </Box>
        </Box>
        <IconButton
          onClick={(event) => {
            handleClose(event)
          }}
          style={{ position: "absolute", top: "8px", right: "8px" }}
        >
          <CloseIcon />
        </IconButton>
      </Drawer>
    </React.Fragment>
  );
}
