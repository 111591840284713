import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginDetails: {},
  image: [],
  activeLang: "",
  // incubationData:{},
  routesData: {},
  rolesData: {},
  // mprData:{},
  sideBarContent: [],
  categoryData: {},
  posttypeData: {},
  ssoData: {},
  sectorData: {},
  subSectorData: {},
  lacData: {},
  pcData: {},
  betData: {},
  platformData: {},
  priorityData: {},
  stateData: {},
  districtData: {},
  blockData:{},
  teshilData:{},
  specializationData:{},
  industryData:{},
  startUpStage:{},
  grievanceId:"",
  userProfile:{},
  forgetEmail:""
};

const GlobalSlice = createSlice({
  name: "start_up",
  initialState,
  reducers: {
    // here we can make a slice for the Global State management to avoid prop drilling
    setLoginDetails: (state, action) => {
      state.loginDetails = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    setActiveLang: (state, action) => {
      state.activeLang = action.payload;
    },
    // setIncubationData:(state,action) => {
    //     state.incubationData=action.payload
    // },
    setRoutesData: (state, action) => {
      state.routesData = action.payload;
    },
    setRolesData: (state, action) => {
      state.rolesData = action.payload;
    },
    // setMprData:(state,action) => {
    //     state.mprData=action.payload
    // },
    setSideBarContent: (state, action) => {
      state.sideBarContent = action.payload;
    },
    setCategoryData: (state, action) => {
      state.categoryData = action.payload;
    },
    setPostTypeData: (state, action) => {
      state.posttypeData = action.payload;
    },
    setSsoData: (state, action) => {
      state.ssoData = action.payload;
    },
    setSectorData: (state, action) => {
      state.sectorData = action.payload;
    },
    setLacData: (state, action) => {
      state.lacData = action.payload;
    },
    setSubSectorData: (state, action) => {
      state.subSectorData = action.payload;
    },
    setBlockData: (state, action) => {
      state.blockData = action.payload;
    },
    setPCData: (state, action) => {
      state.pcData = action.payload;
    },
    setBETData: (state, action) => {
      state.betData = action.payload;
    },
    setPlatformData: (state, action) => {
      state.platformData = action.payload;
    },
    setPriorityData: (state, action) => {
      state.priorityData = action.payload;
    },
    setStateData: (state, action) => {
      state.stateData = action.payload;
    },
    setDistrictData: (state, action) => {
      state.districtData = action.payload;
    },
    setTehsilData: (state, action) => {
      state.teshilData = action.payload;
    },
    setSpecializationData: (state, action) => {
      state.specializationData = action.payload;
    },
    setIndustryData: (state, action) => {
      state.industryData = action.payload;
    },
    setStartUpStage: (state, action) => {
      state.startUpStage = action.payload;
    },
    setGrievanceId: (state, action) => {
      state.grievanceId = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setForgetEmail: (state, action) => {
      state.forgetEmail = action.payload;
    },
  },
});
// here we can export the setFunction to  dispatch the action
export const {
  setLoginDetails,
  setImage,
  setActiveLang,
  setRoutesData,
  setRolesData,
  setMprData,
  setSideBarContent,
  setCategoryData,
  setPostTypeData,
  setSsoData,
  setSectorData,
  setLacData,
  setSubSectorData,
  setPCData,
  setBETData,
  setPlatformData,
  setPriorityData,
  setStateData,
  setDistrictData,
  setBlockData,
  setTehsilData,
  setSpecializationData,
  setIndustryData,
  setStartUpStage,
  setGrievanceId,
  setUserProfile,
  setForgetEmail
} = GlobalSlice.actions;
export default GlobalSlice.reducer;
